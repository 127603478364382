import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { Sev2API, sev2ApiRef } from './api';

export const sev2Plugin = createPlugin({
  id: 'sev2',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: sev2ApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        configApi: configApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, configApi, fetchApi }) => {
        return new Sev2API({
          discoveryApi,
          configApi,
          fetchApi,
        });
      },
    }),
  ],
});

export const Sev2BoardPage = sev2Plugin.provide(
  createRoutableExtension({
    name: 'Sev2BoardPage',
    component: () =>
      import('./components/WrapperComponent').then(m => m.WrapperComponent),
    mountPoint: rootRouteRef,
  }),
);
