import {
  ConfigApi,
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import {
  ProjectRequest,
  ProjectResponse,
  TagRequest,
  Task,
  TaskRequest,
  TaskResponse,
} from '../types';

type Options = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  fetchApi: FetchApi;
};

export const sev2ApiRef = createApiRef<Sev2API>({
  id: 'plugin.sev2.service',
});

export class Sev2API {
  private readonly backendUrl: string | undefined;
  private readonly fetchApi: FetchApi;

  constructor(options: Options) {
    this.backendUrl = options.configApi.getOptionalString('backend.baseUrl');

    this.fetchApi = options.fetchApi;
  }

  private async getUrls() {
    return {
      apiUrl: `${this.backendUrl}/api/sev2/proxy/`,
    };
  }

  async getTasks(params: TaskRequest): Promise<TaskResponse> {
    const { apiUrl } = await this.getUrls();

    const {
      projectPHID = '',
      limit = '100',
      before = '',
      after = '',
      tagPHID = '',
    } = params;

    const reqBodyObj: Record<string, any> = {
      'constraints[projects][0]': projectPHID,
      'attachments[projects]': '1',
      limit: limit,
      before: before,
      after: after,
    };

    if (tagPHID) {
      const reqBodyObjKey = `constraints[projects][1]`;
      reqBodyObj[reqBodyObjKey] = tagPHID;
    }

    const reqBody = JSON.stringify(reqBodyObj);
    const request = await this.fetchApi.fetch(`${apiUrl}maniphest.search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: reqBody,
    });

    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    return request.json();
  }

  async getProject(params: ProjectRequest): Promise<ProjectResponse> {
    const { apiUrl } = await this.getUrls();
    const { projectIds } = params;

    const body = JSON.stringify({
      'constraints[ids][0]': projectIds,
    });

    const request = await this.fetchApi.fetch(`${apiUrl}project.search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    return request.json();
  }

  async getTags(params: TagRequest): Promise<ProjectResponse> {
    const { apiUrl } = await this.getUrls();
    const { tagName } = params;

    const reqBodyObj: Record<string, any> = {};
    const reqBodyObjKey = `constraints[name]`;
    reqBodyObj[reqBodyObjKey] = tagName;
    reqBodyObj['constraints[icons][0]'] = ['tag'];
    const body = JSON.stringify(reqBodyObj);

    const request = await this.fetchApi.fetch(`${apiUrl}project.search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }
    return request.json();
  }

  async getTaskByProjectBoard(params: TaskRequest): Promise<TaskResponse> {
    const {
      after = '',
      before = '',
      limit = '10',
      projectBoardUrl = '',
      tagName = '',
    } = params;

    const projectIds = projectBoardUrl.replace(/.*\/(\d+)\/?$/, '$1');

    const project = await this.getProject({
      projectIds: projectIds,
    });

    const tagId = await this.getTags({
      tagName,
    });

    const tagPHID = tagId?.result?.data.find(
      it => it?.fields.name === tagName,
    )?.phid;

    const getTaskPayload = {
      projectPHID: project?.result?.data?.at(0)?.phid,
      tagPHID: tagPHID,
      after,
      before,
      limit,
    };

    const tasks = await this.getTasks(getTaskPayload);

    if (!tasks) {
      throw new Error(`failed to fetch data, status `);
    }
    return tasks;
  }

  async getAllTaskByProjectBoard(params: TaskRequest): Promise<Task[]> {
    const {
      after = '',
      before = '',
      limit = '100',
      projectBoardUrl = '',
      tagName = '',
    } = params;

    const projectIds = projectBoardUrl.replace(/.*\/(\d+)\/?$/, '$1');

    const project = await this.getProject({
      projectIds: projectIds,
    });

    const tagId = await this.getTags({
      tagName,
    });

    const tagPHID = tagId?.result?.data.find(
      it => it?.fields.name === tagName,
    )?.phid;

    const getTaskPayload = {
      projectPHID: project?.result?.data?.at(0)?.phid,
      tagPHID: tagPHID,
      after,
      before,
      limit,
    };

    const tasks = await this.getTasks(getTaskPayload);

    if (!tasks) {
      throw new Error(`failed to fetch data, status `);
    }

    const mergedTask: Task[] = [...tasks.result.data];
    let nextCursor = tasks.result.cursor?.after;

    while (nextCursor) {
      const nextPayload = { ...getTaskPayload, after: nextCursor }; // Create a new payload with updated cursor
      const nextTasks = await this.getTasks(nextPayload);

      if (!nextTasks || !nextTasks.result?.data) {
        throw new Error('Failed to fetch next batch of data');
      }

      mergedTask.push(...nextTasks.result.data);
      nextCursor = nextTasks.result.cursor?.after; // Update the cursor
    }
    return mergedTask;
  }
}
